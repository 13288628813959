import React from 'react';
import Sidebar from "../sidebar";

const MainWrapper = ({children}) => {
    return <>
        <Sidebar/>
        {children}
    </>
}

export default MainWrapper;