import http from './httpService';
import {
    deleteImageFromGCloudEndpoint,
    productEndpoint,
    productsEndpoint,
    uploadImageToGCloudEndpoint
} from "../constant/constants";
import {getAuthHeaders, getAuthHeadersWithBucketPath, getAuthHeadersWithMultipart} from "./serviceHelper";

export async function getProducts() {
    return http.get(productsEndpoint, getAuthHeaders());
}

export async function getProductByID(id) {
    return http.get(`${productEndpoint}/${id}`, getAuthHeaders());
}

export async function editProduct(id, body) {
    return http.put(`${productEndpoint}/${id}`, body, getAuthHeaders());
}

export async function uploadImage(image, bucketPath) {
    const formData = new FormData();
    formData.append('image_file', image);
    return http.post(uploadImageToGCloudEndpoint, formData, getAuthHeadersWithMultipart(bucketPath));
}

export async function deleteImage(image, bucketPath) {
    return http.delete(`${deleteImageFromGCloudEndpoint}/${image}`, getAuthHeadersWithBucketPath(bucketPath));
}


export async function addProduct(body) {
    return http.post(productEndpoint, body, getAuthHeaders());
}

export async function deleteProductByID(id) {
    return http.delete(`${productEndpoint}/${id}`, getAuthHeaders());
}