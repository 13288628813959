import styled from "styled-components";

export const FormSelect = styled.select`
  border-radius: 10px;
  border: 1px solid #d1d1d1;
  height: 40px;
  font-size: 13px;
  font-weight: 500;
  padding-left: 1rem;
  padding-right: 40px;
  outline: none;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0.5rem 0;
  background: transparent;
`;