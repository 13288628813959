import React from 'react';
import {SidebarContainer, SidebarMenu, SidebarMenuWrapper, SidebarWrapper} from "./sidebarElements";
import {MenuData} from "../../data/menu";

const Sidebar = (props) => {
    return <SidebarWrapper>
        <SidebarContainer>
            <h1>Cataluna</h1>
            <span><a style={{cursor: "pointer", textDecoration: "none"}} href="https://bykace.com"
                     target="_blank" rel="noreferrer">bykace</a></span>
            <SidebarMenuWrapper>
                {MenuData.map((menu, index) =>
                    <SidebarMenu key={index} onClick={() => window.location = menu['path']}>
                        <img src={menu['icon']} alt=""/>
                        <h1>{menu['title']}</h1>
                    </SidebarMenu>)}
            </SidebarMenuWrapper>
        </SidebarContainer>
    </SidebarWrapper>
}

export default Sidebar;