import styled from "styled-components";

export const FormButton = styled.button`
  border-radius: 10px;
  border: 0;
  color: #fff;
  font-weight: bold;
  background: #333;
  font-size: 1rem;
  padding: 0.75rem 2rem;
  align-self: center;
  margin-top: 1rem;
  cursor: pointer;

  &:disabled {
    background: #d8d7d7;
    color: #333;
  }
`;

export const DeleteWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  transform: translate(-15px, -5px);
  cursor: pointer;
`;