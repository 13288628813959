import React, {Fragment} from 'react';
import {FormSectionWrapper, SectionContentWrapper} from "../../sectionElements";
import Form from "../../../components/form";
import Joi from "joi-browser";
import RichText from "../../../components/form/richText";
import {FormLabel} from "../../../components/form/formElements";
import {BlogCategory} from "../../../data/menu";
import {Images, ImageUpload} from "../../product/addProduct/addProductElements";
import {DeleteWrapper} from "../../../components/form/button/buttonElements";
import Delete from "../../../assets/icons/component/delete.png";
import {toast} from "react-toastify";
import {uploadImage} from "../../../services/productService";
import {createBlog} from "../../../services/blogService";

class AddBlog extends Form {
    state = {
        data: {
            title: "",
            category: "",
            content: "",
            preview_text: "",
            tags: ""
        },
        errors: {},
        objectURLs: [],
        images: [],
        onSubmit: false
    }

    schema = {
        title: Joi.string().required(),
        category: Joi.string().required(),
        content: Joi.string().required(),
        preview_text: Joi.string().required(),
        tags: Joi.string().required()
    }

    setContent = (content) => {
        const {data} = this.state;
        data["content"] = content;
        this.setState({data});
    };

    deleteImageLocally = (imageURL) => {
        const {objectURLs, images} = this.state;
        const objectURL = objectURLs.find((url) => url === imageURL);
        const filteredURLs = objectURLs.filter(function (url) {
            return url !== imageURL
        });

        const deletedURLIndex = objectURLs.indexOf(objectURL);
        const imageName = images[deletedURLIndex].name;
        const filteredImages = images.filter(function (image) {
            return image.name !== imageName
        });
        this.setState({objectURLs: filteredURLs, images: filteredImages});
    }

    doSubmit = async () => {
        const {data, images, onSubmit} = this.state;
        this.setState({onSubmit: true});

        toast.info("Submitting your blog ...");
        if (!onSubmit) {
            if (images.length === 0) {
                toast.error("This product needs at least an image. Please upload an image that represents this product.");
                this.setState({onSubmit: false});
                return;
            }

            let imageURL = "";
            if (images.length !== 0) {
                for (const image of images) {
                    try {
                        const {status, data} = await uploadImage(image, "blogs");
                        if (status === 200) {
                            imageURL = data['pathname'];
                        }
                    } catch (e) {
                        if (e.response && e.response.status === 400) {
                            toast.error(e.response.data.error)
                        }
                    }
                }
                data.image_url = imageURL
            }

            const body = {
                title: data.title,
                category: data.category,
                image_url: data.image_url,
                content: data.content,
                preview_text: data.preview_text,
                tags: data.tags,
            }
            try {
                const {status} = await createBlog(body);
                if (status === 200) {
                    toast.success("Blog Created");
                    setTimeout(function () {
                        window.location = "/blogs";
                    }, 500);
                } else {
                    toast.warning(status);
                }
            } catch (e) {
                if (e.response && e.response.status === 400) {
                    toast.error(e.response.data.error);
                }
            }
        } else {
            toast.warning("Submitting Blog ...");
        }
    }

    render() {
        const {objectURLs} = this.state;
        return (
            <SectionContentWrapper>
                <form onSubmit={this.handleSubmit}>
                    <FormSectionWrapper>
                        <h1>Detail</h1>
                        <span>Tell us the detail of the blog</span>
                        {this.renderInput("title", "Title*")}
                        {this.renderSelect("category", BlogCategory, "Category*")}
                        {this.renderTextArea("preview_text", "Preview Text*", "Berikan teks preview singkat tentang blog ini")}
                        <FormLabel>Content*</FormLabel>
                        <RichText setContent={this.setContent}/>
                        {this.renderInput("tags", "Tags (Comma Separated)", `Separate each tag with comma (",")`)}
                    </FormSectionWrapper>
                    <FormSectionWrapper>
                        <FormLabel>Image Preview</FormLabel>
                        <Images>
                            {objectURLs &&
                                objectURLs.map((url) => (
                                    <Fragment key={url}>
                                        <img src={url} alt="" width={100}/>
                                        <DeleteWrapper onClick={() => this.deleteImageLocally(url)}>
                                            <img src={Delete} alt=""/>
                                        </DeleteWrapper>
                                    </Fragment>
                                ))
                            }
                            <ImageUpload
                                htmlFor="image_urls">
                                Upload
                            </ImageUpload>
                        </Images>
                        {this.renderInputFile("image_urls", "", "file", true)}
                    </FormSectionWrapper>
                    {this.renderButton('Submit')}
                </form>
            </SectionContentWrapper>
        );
    }
}

export default AddBlog;