/*
|--------------------------------------------------------------------------
| PRODUCT MENU
|--------------------------------------------------------------------------
|
|
*/

import {menuAdd, menuGet, menuGetArchived} from "../constant/constants";

export const ProductMenu = [
    {
        label: "View Products",
        value: menuGet
    },
    {
        label: "Add Product",
        value: menuAdd
    }
];

/*
|--------------------------------------------------------------------------
| BANNER MENU
|--------------------------------------------------------------------------
|
|
*/

export const BannerMenu = [
    {
        label: "View Banners",
        value: menuGet
    },
    {
        label: "View Archived Banners",
        value: menuGetArchived
    },
    {
        label: "Add Banner",
        value: menuAdd
    }
];

/*
|--------------------------------------------------------------------------
| BLOGS MENU
|--------------------------------------------------------------------------
|
|
*/

export const BlogsMenu = [
    {
        label: "Add Blog",
        value: menuAdd
    },
    {
        label: "View Blogs",
        value: menuGet
    }
];
