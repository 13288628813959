import styled from "styled-components";

export const FormTextArea = styled.textarea`
  border-radius: 10px;
  border: 1px solid #d1d1d1;
  height: ${({height}) => (height ? height : "120px")};
  display: block;
  font-size: 13px;
  padding: 8px 1rem 20px;
  outline: none;
  width: 100%;
  text-align: justify;
  background: transparent;
  resize: vertical;
  max-height: 10rem;
  margin: 0.5rem 0;

  @media (min-width: 1024px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;