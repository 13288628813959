import React, {useContext} from 'react';
import {NavbarFlex, NavbarMenuWrapper, NavbarWrapper} from "./navbarElements";
import {DataContext} from "../DataContext";
import {toast} from "react-toastify";
import {logout} from "../../services/authService";

const Navbar = ({menus}) => {
    const {setMenu} = useContext(DataContext);

    const userLogout = async () => {
        try {
            const {status} = await logout();
            if (status === 200) {
                window.location = "/";
            }
        } catch (e) {
            if (e.response && e.response.status === 400) {
                toast.error(e.response.data.error);
            }
        }
    }

    return <NavbarWrapper>
        <NavbarFlex>
            <NavbarMenuWrapper>
                {menus && menus.map((menu, index) =>
                    <h1 key={index} onClick={() => setMenu(menu['value'])}>{menu['label']}</h1>)}
            </NavbarMenuWrapper>
            <h1 onClick={() => userLogout()}>Logout</h1>
        </NavbarFlex>
    </NavbarWrapper>
}

export default Navbar;