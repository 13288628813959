import React from 'react';
import {ModalFormWrapper} from "../../../../components/modalForm/globalModalElements";
import {BlogDetailWrapper} from "./style";
import {timeSince} from "../../../../utils/utils";

const BlogDetail = ({blog, setIsEdit}) => (
    <ModalFormWrapper>
        <BlogDetailWrapper>
            <h2>{blog['category']}</h2>
            <img src={blog['image_url']} alt=""/>
            <h1>{blog['title']}</h1>
            <p>{blog['preview_text']}</p>
            <span>{timeSince(new Date(blog['created_at']))}</span>
            <button onClick={setIsEdit}>Edit</button>
        </BlogDetailWrapper>
    </ModalFormWrapper>
);

export default BlogDetail;