import React from 'react';
import {FormLabel, FormSection} from "../formElements";
import {FormSelect} from "./selectElements";

const Select = ({error, label, name, options, ...rest}) => {
    return (
        <FormSection>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <FormSelect error={error} id={name} name={name} {...rest}>
                <option value=""/>
                {options.map((option) => (
                    <option key={option.title || option} value={option.name || option}>
                        {option.title || option}
                    </option>
                ))}
            </FormSelect>
        </FormSection>
    );
};

export default Select;