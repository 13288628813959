import styled from "styled-components";

export const FormInput = styled.input`
  border-radius: 10px;
  border: 1px solid #d1d1d1;
  height: 40px;
  display: block;
  font-size: 13px;
  padding-left: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: 100%;
  margin: 0.5rem 0 1rem;
  background: transparent;

  &:hover {
    outline: 0;
    box-shadow: none;
  }

  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`;