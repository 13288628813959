import styled from "styled-components";

export const FormInputFile = styled.input`
  background-color: #f8fafc;
  font-size: 1rem;
  display: none;
  width: 100%;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;

  &:focus {
    outline: 0;
  }
`;