import React from 'react';
import {FormInput} from "./inputElements";
import {FormLabel, InputWrapper} from "../formElements";

const Input = ({error, name, label, type, ...rest}) => {
    return (
        <InputWrapper>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <FormInput id={name} name={name} type={type} {...rest} error={error}/>
        </InputWrapper>
    );
};

export default Input;