import React, {Component} from 'react';
import MainWrapper from "../../components/mainWrapper";
import Navbar from "../../components/navbar";
import {BannerMenu} from "../../data/navbarMenu";
import {DataContext} from "../../components/DataContext";
import {SectionContainer} from "../sectionElements";
import {menuGet, menuGetArchived} from "../../constant/constants";
import ViewBanner from "./viewBanner";
import AddBanner from "./addBanner";
import ViewArchivedBanner from "./viewArchivedBanner";

class Banner extends Component {
    static contextType = DataContext;

    render() {
        const {menu} = this.context;
        return (
            <MainWrapper>
                <Navbar menus={BannerMenu}/>
                <SectionContainer>
                    {
                        menu === menuGet ? (<ViewBanner/>) : menu === menuGetArchived ? (<ViewArchivedBanner/>) : (
                            <AddBanner/>)
                    }
                </SectionContainer>
            </MainWrapper>
        );
    }
}

export default Banner;