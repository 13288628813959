import React, {Fragment} from 'react';
import {ModalFormWrapper} from "../../../components/modalForm/globalModalElements";
import Joi from "joi-browser";
import Form from "../../../components/form";
import {deleteImage, editProduct, getProductByID, uploadImage} from "../../../services/productService";
import {toast} from "react-toastify";
import {FormFlex, FormSectionWrapper} from "../../sectionElements";
import {
    accentsType,
    beddingAndBathCategories,
    carpetAndRugsCategories,
    collection,
    curtainsAndMaterialCategories,
    decorCategories,
    furnitureCategories,
    rooms,
    seatingTypes,
    storageDisplayTypes,
    superCategories,
    systemFurnitureCategories,
    tableTypes
} from "../addProduct/addProductOptions";
import {Images, ImageUpload} from "../addProduct/addProductElements";
import {DeleteWrapper} from "../../../components/form/button/buttonElements";
import Delete from "../../../assets/icons/component/delete.png";
import {Multiselect} from "multiselect-react-dropdown";
import {FormLabel} from "../../../components/form/formElements";

class ProductForm extends Form {
    state = {
        data: {
            id: 0,
            sku: "",
            name: "",
            image_urls: [],
            product_size: {
                width: 0,
                height: 0,
                depth: 0,
            },
            detail_size: {
                width: 0,
                height: 0,
                depth: 0,
            },
            super_category: "",
            category: "",
            type: "",
            price: 0,
            discount_price: 0,
            weight: 0,
            description: "",
            products_sold: 0,
            quantity: 0,
            material: "",
            finishing: "",
            collection: "",
            colors: "",
        },
        errors: [],
        deletedImages: [],
        objectURLs: [],
        images: [],
        selectedRoom: [],
        rooms: []
    }

    schema = {
        id: Joi.number().min(0).label("ID"),
        sku: Joi.string().required().label("SKU"),
        name: Joi.string().required().label("Name"),
        image_urls: Joi.array().required().items(Joi.string()),
        product_size: Joi.object().required().keys({
            width: Joi.number().min(0).required().label("Product Size Width"),
            height: Joi.number().min(0).required().label("Product Size Height"),
            depth: Joi.number().min(0).required().label("Product Size Depth"),
        }),
        detail_size: Joi.object().keys({
            width: Joi.number().min(0).label("Detail Size Width"),
            height: Joi.number().min(0).label("Detail Size Height"),
            depth: Joi.number().min(0).label("Detail Size Depth"),
        }),
        super_category: Joi.string().required().label("Super Category"),
        category: Joi.string().required().label("Category"),
        type: Joi.string().allow("").label("Type"),
        price: Joi.number().min(0).label("Price"),
        discount_price: Joi.number().min(0).label("Discount Price"),
        weight: Joi.number().min(0).label("Weight"),
        description: Joi.string().allow("").label("Description"),
        quantity: Joi.number().min(0).label("Quantity"),
        material: Joi.string().required().label("Material"),
        products_sold: Joi.number().min(0).label("Product Sold"),
        finishing: Joi.string().required().label("Finishing"),
        collection: Joi.string().required().label("Collection"),
        colors: Joi.string().allow("").label("Colors"),
        rooms: Joi.array().items(Joi.string()),
    }

    async componentDidMount() {
        const {currentProduct} = this.props;
        try {
            const {status, data} = await getProductByID(currentProduct['id']);
            if (status === 200) {
                const selectedRoom = rooms.filter((room) => data['rooms'].some((value) => room.id.includes(value)));
                this.setState({data, selectedRoom})
            }
        } catch (e) {
            if (e.response && e.response.status === 400) {
                toast.error(e.response.data.error)
            }
        }
    }

    deleteImage = async (image) => {
        const {data, deletedImages} = this.state;
        const filteredImageURLs = data.image_urls.filter((url) => url !== image);
        data.image_urls = filteredImageURLs;
        deletedImages.push(image);
        this.setState({deletedImages, data});
    }

    deleteImageLocally = (imageURL) => {
        const {objectURLs, images} = this.state;
        const objectURL = objectURLs.find((url) => url === imageURL);
        const filteredURLs = objectURLs.filter(function (url) {
            return url !== imageURL
        });

        const deletedURLIndex = objectURLs.indexOf(objectURL);
        const imageName = images[deletedURLIndex].name;
        const filteredImages = images.filter(function (image) {
            return image.name !== imageName
        });
        this.setState({objectURLs: filteredURLs, images: filteredImages});
    }

    doSubmit = async () => {
        const {deletedImages, images, data} = this.state;

        if (data.image_urls.length === 0 && images.length === 0) {
            toast.error("This product needs at least an image. Please upload an image that represents this product.");
            return;
        }


        if (deletedImages.length !== 0) {
            for (const image of deletedImages) {
                const split = image.split('/');
                try {
                    const {status} = await deleteImage(split[6], "products");
                    if (status !== 200) {
                        return
                    }
                } catch (e) {
                    if (e.response && e.response.status === 400) {
                        toast.error(e.response.data.error)
                    }
                }
            }
        }

        const urls = [];
        if (images.length !== 0) {
            for (const image of images) {
                try {
                    const {status, data} = await uploadImage(image, "products");
                    if (status === 200) {
                        const imageURL = data['pathname'];
                        urls.push(imageURL)
                    }
                } catch (e) {
                    if (e.response && e.response.status === 400) {
                        toast.error(e.response.data.error)
                    }
                }
            }
            data.image_urls = [...data.image_urls, ...urls];
        }

        const body = {
            product_size: data.product_size,
            detail_size: data.detail_size,
            sku: data.sku,
            name: data.name,
            domain: data.domain,
            price: data.price,
            discount_price: data.discount_price,
            weight: data.weight,
            description: data.description,
            quantity: data.quantity,
            material: data.material,
            finishing: data.finishing,
            super_category: data.super_category,
            category: data.category,
            type: data.type,
            products_sold: data.products_sold,
            collection: data.collection,
            room: data.room,
            colors: data.colors,
            image_urls: data.image_urls
        };

        try {
            const id = data.id;
            const {status} = await editProduct(id, body);
            if (status === 200) {
                toast.success("Product Edited");
                setTimeout(function () {
                    window.location = "/products";
                }, 500);
            } else {
                toast.warning(status);
            }
        } catch (e) {
            if (e.response && e.response.status === 400) {
                toast.error(e.response.data.error);
            }
        }
    }

    onSelect = (selectedList, selectedItem) => {
        const {rooms} = this.state;
        const addedRooms = [...rooms, selectedItem['id']];
        this.setState({selectedRoom: selectedList, rooms: addedRooms});
    }

    onRemove = (selectedList, removedItem) => {
        const {rooms} = this.state;
        const removedRooms = rooms.filter((room) => room !== removedItem.id);
        this.setState({selectedRoom: selectedList, rooms: removedRooms});
    }

    render() {
        const {super_category, category, image_urls} = this.state.data;
        const {objectURLs, selectedRoom} = this.state;
        return (
            <ModalFormWrapper>
                <h1>Detail</h1>
                <form onSubmit={this.handleSubmit}>
                    <FormFlex>
                        <FormSectionWrapper>
                            <h1>Basic Detail of Product</h1>
                            <span>Please input some basic detail of the product</span>
                            {this.renderInput("sku", "SKU*")}
                            {this.renderInput("name", "Name*")}
                            {this.renderInput("quantity", "Quantity*", "", "number")}
                        </FormSectionWrapper>
                        <FormSectionWrapper>
                            <h1>Product Dimension</h1>
                            <span>Tell us the specific measurement of the product</span>
                            {this.renderInput("product_size", "Product Width*", "", "number", "width")}
                            {this.renderInput("product_size", "Product Height*", "", "number", "height")}
                            {this.renderInput("product_size", "Product Depth*", "", "number", "depth")}
                        </FormSectionWrapper>
                    </FormFlex>
                    <FormFlex>
                        <FormSectionWrapper>
                            <h1>Product Category</h1>
                            <span>Tell us the specific category of the product</span>
                            {this.renderSelect(
                                "super_category",
                                superCategories,
                                "Super Category*"
                            )}
                            {this.renderSelect(
                                "category",
                                super_category === "Furniture" || super_category === "Prive"
                                    ? furnitureCategories
                                    : super_category === "System-Furniture"
                                        ? systemFurnitureCategories
                                        : super_category === "Artwork"
                                            ? decorCategories
                                            : super_category === "Curtains-Material"
                                                ? curtainsAndMaterialCategories
                                                : super_category === "Bedding-Bath"
                                                    ? beddingAndBathCategories
                                                    : super_category === "Carpet-Rugs"
                                                        ? carpetAndRugsCategories
                                                        : [],
                                "Category*"
                            )}
                            {this.renderSelect(
                                "type",
                                category === "Seating"
                                    ? seatingTypes
                                    : category === "Storage-Display"
                                        ? storageDisplayTypes
                                        : category === "Table"
                                            ? tableTypes
                                            : category === "Accents"
                                                ? accentsType
                                                : [],
                                "Type"
                            )}
                        </FormSectionWrapper>
                        <FormSectionWrapper>
                            <h1>Product Price $$</h1>
                            <span>Tell us the specific price of the product</span>
                            {this.renderInput("price", "Price*", "", "number")}
                            {this.renderInput("discount_price", "Discount Price*", "", "number")}
                        </FormSectionWrapper>
                    </FormFlex>
                    <FormSectionWrapper>
                        <h1>Product Detail</h1>
                        <span>Tell us the specific detail of the product size</span>
                        {this.renderInput("material", "Material*")}
                        {this.renderInput("finishing", "Finishing*")}
                        {this.renderInput("weight", "Weight*", "", "number")}
                    </FormSectionWrapper>

                    <FormSectionWrapper>
                        <h1>Product Detail Size</h1>
                        <span>If there's any complimentary product on the main product, please specify the size too</span>
                        {this.renderInput("detail_size", "Detail Width", "", "number", "width")}
                        {this.renderInput("detail_size", "Detail Height", "", "number", "height")}
                        {this.renderInput("detail_size", "Detail Depth", "", "number", "depth")}
                    </FormSectionWrapper>

                    <FormSectionWrapper>
                        <h1>Product Description</h1>
                        <span>Tell us the specific description of the product</span>
                        {this.renderTextArea("description", "Description*", "Beli kursi ini, dijamin punggung kamu akan lebih lurus dan sehat. Produktif deh ngerjain tugas.")}
                    </FormSectionWrapper>
                    <FormSectionWrapper>
                        <h1>Product Additional Details</h1>
                        <span>Tell us some additional detail of the product</span>
                        {this.renderSelect("collection", collection, "Collection*")}
                        <FormLabel style={{marginBottom: "0.5rem"}}>Room*</FormLabel>
                        <Multiselect
                            options={rooms}
                            selectedValues={selectedRoom}
                            onSelect={this.onSelect}
                            onRemove={this.onRemove}
                            displayValue="name"
                            placeholder=""
                        />
                        {this.renderInput("colors", "Colors")}
                    </FormSectionWrapper>
                    <FormSectionWrapper>
                        <Images>
                            {objectURLs &&
                                objectURLs.map((url) => (
                                    <Fragment key={url}>
                                        <img src={url} alt="" width={100}/>
                                        <DeleteWrapper onClick={() => this.deleteImageLocally(url)}>
                                            <img src={Delete} alt=""/>
                                        </DeleteWrapper>
                                    </Fragment>
                                ))
                            }
                            {image_urls.length > 0 && image_urls.map((url) => <Fragment key={url}>
                                <img src={url} alt="" width={100}/>
                                <DeleteWrapper onClick={() => this.deleteImage(url)}>
                                    <img src={Delete} alt=""/>
                                </DeleteWrapper>
                            </Fragment>)}
                            <ImageUpload
                                htmlFor="image_urls">
                                Upload
                            </ImageUpload>
                        </Images>
                        {this.renderInputFile("image_urls", "", "file", true)}
                    </FormSectionWrapper>
                    {this.renderButton("Edit")}
                </form>
            </ModalFormWrapper>
        );
    }
}

export default ProductForm;