import styled from "styled-components";

export const AuthSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const AuthWrapper = styled.div`
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 10px;
  width: 400px;
  padding: 3rem;
`;

export const AuthHeader = styled.div`
  text-align: center;

  & > h1 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  & > span {
    font-size: 14px;
  }
`;

export const AuthBody = styled.div`
  margin-top: 1.5rem;
`;