import React from 'react';
import {Modal} from "react-bootstrap";

const ModalComponent = ({body, onHide, show, size = "m"}) => {
    return (
        <Modal
            show={show}
            size={size}
            centered
            onHide={onHide}
        >
            <Modal.Body>{body}</Modal.Body>
        </Modal>
    );
};

export default ModalComponent;