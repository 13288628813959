import http from './httpService';
import {authEndpoint, logoutEndpoint, tokenKey} from "../constant/constants";
import {getAuthHeaders, getLoginHeaders} from "./serviceHelper";

export async function login(body) {
    const {data} = await http.post(authEndpoint, body, getLoginHeaders());
    localStorage.setItem(tokenKey, data["access_token"]);
}

export async function logout() {
    await http.delete(logoutEndpoint, getAuthHeaders());
    localStorage.removeItem(tokenKey);
}