import React from 'react';
import {ClickableSpan} from "../../components/table/tableBody/tableBodyElements";
import Table from "../../components/table";

const OrderTable = ({data, onSort, sortColumn, showModal, setCurrentOrder}) => {
    const columns = [
        {path: "id", label: "ID"},
        {
            path: "full_name",
            label: "Ordered By",
            content: (order) => (
                <ClickableSpan onClick={() => {
                    showModal();
                    setCurrentOrder(order);
                }}
                >
                    {order.full_name}
                </ClickableSpan>
            )
        },
        {path: "status", label: "Status"},
        {path: "total_price", label: "Total Price"},
        {path: "created_at", label: "Created at"},
        // {
        //     key: "edit",
        //     content: (order) => (
        //         <RiIcons.RiEditBoxLine
        //             style={{ cursor: "pointer" }}
        //             onClick={() => this.handleModal(order)}
        //         />
        //     ),
        // },
    ];

    return (<Table
        columns={columns}
        data={data}
        onSort={onSort}
        sortColumn={sortColumn}
    />);
}

export default OrderTable;