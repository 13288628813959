import React, {Component} from 'react';
import {toast} from "react-toastify";
import {getOrders} from "../../services/orderService";
import MainWrapper from "../../components/mainWrapper";
import {LoadingWrapper, SectionContainer, SectionContentWrapper, SectionFlex} from "../sectionElements";
import Lottie from "lottie-react";
import Loading from "../../assets/lottie/loading.json";
import Pagination from "../../components/pagination";
import _ from "lodash";
import {paginate} from "../../utils/utils";
import SearchBox from "../../components/searchBox";
import OrderTable from "../../tables/orderTable";
import ModalForm from "../../components/modalForm";
import OrderDetail from "./orderDetail";

class Order extends Component {
    state = {
        pageSize: 10,
        currentPage: 1,
        orders: [],
        sortColumn: {path: "full_name", order: "asc"},
        searchQuery: "",
        isLoading: false,
        showOrderModal: false,
        currentOrder: {}
    };


    async componentDidMount() {
        try {
            this.setState({isLoading: true})
            const {status, data: orders} = await getOrders();
            if (status === 200) {
                this.setState({orders, isLoading: false});
            }
        } catch (e) {
            if (e.response && e.response.status === 400) {
                toast.error(e.response.data.error);
            }
        }
    }

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleSearch = (query) => {
        this.setState({searchQuery: query, currentPage: 1});
    };

    handleSort = (sortColumn) => {
        this.setState({sortColumn});
    };

    handleOrderDetailModal = () => {
        const showOrderModal = !this.state.showOrderModal;
        this.setState({showOrderModal});
    }

    setCurrentOrder = (currentOrder) => {
        this.setState({currentOrder})
    }


    getSortedData = () => {
        const {
            pageSize,
            currentPage,
            orders,
            sortColumn,
            searchQuery,
        } = this.state;

        let filtered = orders;
        if (searchQuery)
            filtered = orders.filter((user) =>
                user.full_name.toLowerCase().includes(searchQuery.toLowerCase())
            );

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
        const paginatedOrders = paginate(sorted, currentPage, pageSize);

        return {totalCount: sorted.length, sortedOrders: paginatedOrders};
    };


    render() {
        const {totalCount, sortedOrders} = this.getSortedData();
        const {pageSize, sortColumn, searchQuery, isLoading, showOrderModal, currentOrder} = this.state;
        return (
            <MainWrapper>
                <SectionContainer>
                    <SectionContentWrapper>
                        <h1>Orders</h1>
                        <SearchBox value={searchQuery} onChange={this.handleSearch}/>
                        {isLoading ? <LoadingWrapper>
                            <Lottie animationData={Loading}/>
                        </LoadingWrapper> : <SectionFlex flexBasis="500xp">
                            <div><OrderTable
                                data={sortedOrders}
                                sortColumn={sortColumn}
                                onSort={this.handleSort}
                                showModal={this.handleOrderDetailModal}
                                setCurrentOrder={this.setCurrentOrder}
                            /></div>
                            <Pagination
                                itemsCount={totalCount}
                                pageSize={pageSize}
                                onPageChange={this.handlePageChange}
                            />
                        </SectionFlex>}
                        {showOrderModal ? <ModalForm
                            show={showOrderModal}
                            onHide={this.handleOrderDetailModal}
                            size="lg"
                            body={<OrderDetail id={currentOrder['id']}
                            />}
                        /> : null}
                    </SectionContentWrapper>
                </SectionContainer>
            </MainWrapper>
        );
    }
}

export default Order;