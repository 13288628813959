import styled from "styled-components";

export const SectionContainer = styled.div`
  margin-left: 200px;
  padding: 4rem 2rem;
`;

export const SectionContentWrapper = styled.div`

  & > h1 {
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
  }

  & > p {
    font-size: 14px;
    margin-bottom: 1.5rem;
  }
`;

export const SectionFlex = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    flex-basis: ${({flexBasis}) => flexBasis};
  }
`

export const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  & > div {
    width: 300px;
  }
`;

export const FormFlex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3rem
`;

export const FormSectionWrapper = styled.div`
  margin-top: 2rem;
  width: 100%;

  & > h1 {
    font-size: 1rem;
    padding-bottom: 2px;
    background: linear-gradient(to top,
    #75d481 50%,
    transparent 50%);
    display: inline;
  }

  & > span {
    margin-top: 0.25rem;
    display: block;
    font-size: 13px;
    color: #212529;
  }
`;

export const FormDetailWrapper = styled.div`
  margin-top: 0.7rem;

  & > span {
    display: block;
    font-size: 13px;
    color: #212529;
  }
`;