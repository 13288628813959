import React from "react";
import {Redirect, Route} from "react-router-dom";
import {getCurrentUser} from "../../services/serviceHelper";

const MainRoute = ({component: Component, render, ...rest}) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (getCurrentUser())
                    return (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: {from: props.location},
                            }}
                        />
                    );
                return Component ? <Component {...props} /> : render(props);
            }}
        />
    );
};

export default MainRoute;