import styled from "styled-components";

export const BlogDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > img {
    width: 100%;
  }

  & > h1 {
    font-size: 16px;
    margin: 8px 0;
  }

  & > h2 {
    font-size: 14px;
    color: #fff;
    background: #333;
    border-radius: 8px;
    padding: 4px 8px;
    align-self: flex-start;
    margin-bottom: 8px;
  }

  & > p {
    font-size: 14px;
    margin-bottom: 1rem;
  }

  & > span {
    font-size: 12px;
  }

  & > button {
    align-self: flex-start;
    margin-top: 1rem;
    border: 0;
    outline: none;
    padding: 4px 12px;
    background: #094469;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
  }
`;