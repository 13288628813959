export const MenuData = [
    {
        title: "Users",
        path: "/",
        icon: require('../assets/icons/sidebar/menu-user.png')
    },
    {
        title: "Products",
        path: "/products",
        icon: require('../assets/icons/sidebar/menu-furniture.png')
    },
    {
        title: "Banners",
        path: "/banners",
        icon: require('../assets/icons/sidebar/menu-banner.png')
    },
    {
        title: "Orders",
        path: "/orders",
        icon: require('../assets/icons/sidebar/menu-order.png')
    },
    {
        title: "Blogs",
        path: "/blogs",
        icon: require('../assets/icons/sidebar/menu-blogs.png')
    },
];

export const BlogCategory = ["News", "Tips", "Profile", "Feature"];