import styled from "styled-components";

export const BannerGridWrapper = styled.div`
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
`;

export const BannerWrapper = styled.div`
  & > img {
    width: 300px;
    margin-bottom: 1rem;
  }
`;

export const BannerFlexOption = styled.div`
  display: flex;


  & > h1 {
    padding: 3px;
    background: indianred;
    font-size: 14px;
    color: #fff;
    text-align: center;
    width: 100px;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 1.5rem;
  }
`;