import styled from "styled-components";

export const SidebarWrapper = styled.div`
  border-right: 1px solid #eee;
  position: fixed;
  width: 200px;
  height: 100%;
`;

export const SidebarContainer = styled.div`
  padding: 2rem 1.5rem;

  & > h1 {
    font-size: 1.5rem;
    margin: 0;
  }

  & > span {
    font-size: 12px;
    color: #9D9D9D;
  }
`;

export const SidebarMenuWrapper = styled.div`
  margin-top: 4rem;
`;

export const SidebarMenu = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.25rem;
  cursor: pointer;

  & > img {
    margin-right: 1.5rem;
    width: 25px;
    height: 25px;
  }

  & > h1 {
    font-size: 14px;
  }
`;