import {createGlobalStyle} from "styled-components";

import InterBoldWoff from "../assets/fonts/inter/Inter-Bold.woff";
import InterBoldWoff2 from "../assets/fonts/inter/Inter-Bold.woff2";
import InterMediumWoff from "../assets/fonts/inter/Inter-Medium.woff";
import InterMediumWoff2 from "../assets/fonts/inter/Inter-Medium.woff2";
import InterRegularWoff from "../assets/fonts/inter/Inter-Regular.woff";
import InterRegularWoff2 from "../assets/fonts/inter/Inter-Regular.woff2";

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    src: url('${InterMediumWoff2}') format('woff2'),
    url('${InterMediumWoff}') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Inter';
    src: url('${InterRegularWoff2}') format('woff2'),
    url('${InterRegularWoff}') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Inter';
    src: url('${InterBoldWoff2}') format('woff2'),
    url('${InterBoldWoff}') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
    font-family: 'Inter', sans-serif;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6, p {
    color: #333333;
    margin: 0;
    letter-spacing: -.2px;
  }

  h1, h2, h3 {
    font-weight: 600;
  }

  .page-item.active .page-link {
    background-color: #333;
    border-color: #333;
  }

  .page-link:hover {
    color: #333;
  }

  .page-link {
    color: #333;
  }

  .page-link:focus,
  a:focus {
    outline: 0;
    box-shadow: none;
  }

  table {
    background: #fff;
    border-collapse: collapse;
    width: 100%;
  }

  th {
    font-size: 12px;
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    padding: 1rem 0.5rem 1rem 0.75rem;
    border-bottom: 1px solid #f4eee8;
    background-color: #333;
  }

  td {
    font-size: 12px;
    padding: 0.75rem;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border-bottom: 1px solid #eee;
  }
`;