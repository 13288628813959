import React, {Component} from 'react';
import {ModalFormWrapper} from "../../../components/modalForm/globalModalElements";
import {FormDetailWrapper, FormFlex, FormSectionWrapper} from "../../sectionElements";
import {toast} from "react-toastify";
import {getOrderByID} from "../../../services/orderService";
import {FormLabel} from "../../../components/form/formElements";

class OrderDetail extends Component {
    state = {
        data: {},
        order: {},
        payment: {},
        shipment: {},
        orderItems: []
    }

    async componentDidMount() {
        const {id} = this.props;
        try {
            const {status, data} = await getOrderByID(id);
            if (status === 200) {
                this.setState({
                    data,
                    order: data['order'],
                    payment: data['payment'],
                    shipment: data['shipment'],
                    orderItems: data['order']['order_items']
                });
            }
        } catch (e) {
            if (e.response && e.response.status === 400) {
                toast.error(e.response.data.error);
            }
        }
    }

    render() {
        const {order, orderItems, payment, shipment} = this.state;
        return (
            <ModalFormWrapper>
                <h1>Order</h1>
                <FormSectionWrapper>
                    <h1>Order Detail</h1>
                    <FormDetailWrapper>
                        <FormLabel>Status</FormLabel>
                        <span>{order['status']}</span>
                        <FormLabel>Total Price</FormLabel>
                        <span>{order['total_price']}</span>
                    </FormDetailWrapper>
                </FormSectionWrapper>

                <FormSectionWrapper>
                    <h1>Order Items Detail ({orderItems.length})</h1>
                    {orderItems.map((orderItem, index) =>
                        <FormDetailWrapper key={index}>
                            <FormLabel>Name</FormLabel>
                            <span>{orderItem['name']}</span>
                            <FormLabel>Price</FormLabel>
                            <span>{orderItem['price']}</span>
                            <FormLabel>Quantity</FormLabel>
                            <span>{orderItem['quantity']}</span>
                            <FormLabel>Discount Price</FormLabel>
                            <span>{orderItem['discount_price']}</span>
                        </FormDetailWrapper>)}
                </FormSectionWrapper>
                <FormSectionWrapper>
                    <h1>Payment Detail</h1>
                    <FormDetailWrapper>
                        <FormLabel>Order Price</FormLabel>
                        <span>{payment['order_price']}</span>
                        <FormLabel>Shipment Price</FormLabel>
                        <span>{payment['shipment_price']}</span>
                        <FormLabel>Invoice URL</FormLabel>
                        <span><a style={{cursor: "pointer", textDecoration: "underline"}} href={payment['invoice_url']}
                                 target="_blank" rel="noreferrer">URL</a></span>
                    </FormDetailWrapper>
                </FormSectionWrapper>
                <FormSectionWrapper>
                    <h1>Shipment Detail</h1>
                    <FormFlex>
                        <FormSectionWrapper>
                            <h1>Destination</h1>
                            <FormDetailWrapper>
                                <FormLabel>Address Label</FormLabel>
                                <span>{shipment['address_label']}</span>
                                <FormLabel>Address</FormLabel>
                                <span>{shipment['destination_address']}</span>
                                <FormLabel>City</FormLabel>
                                <span>{shipment['destination_city']}</span>
                                <FormLabel>Province</FormLabel>
                                <span>{shipment['destination_province']}</span>
                                <FormLabel>Type</FormLabel>
                                <span>{shipment['destination_type']}</span>
                                <FormLabel>Mobile Number</FormLabel>
                                <span>{shipment['mobile_number']}</span>
                                <FormLabel>Receiver</FormLabel>
                                <span>{shipment['receiver']}</span>
                            </FormDetailWrapper>
                        </FormSectionWrapper>
                        <FormSectionWrapper>
                            <h1>Logistic</h1>
                            <FormDetailWrapper>
                                <FormLabel>Service</FormLabel>
                                <span>{shipment['logistic_service']}</span>
                                <FormLabel>Type</FormLabel>
                                <span>{shipment['logistic_type']}</span>
                            </FormDetailWrapper>
                        </FormSectionWrapper>
                    </FormFlex>
                </FormSectionWrapper>
            </ModalFormWrapper>
        );
    }
}

export default OrderDetail;