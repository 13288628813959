import {tokenKey} from "../constant/constants";
import jwtDecode from "jwt-decode";

export function getAuthHeaders() {
    return {
        headers: {
            Authorization: "Bearer " + getToken(),
        }
    };
}

export function getAuthHeadersWithMultipart(bucketpath) {
    return {
        headers: {
            Authorization: "Bearer " + getToken(),
            'content-type': 'multipart/form-data',
            'X-BucketPath': bucketpath
        }
    };
}

export function getAuthHeadersWithBucketPath(bucketpath) {
    return {
        headers: {
            Authorization: "Bearer " + getToken(),
            'X-BucketPath': bucketpath
        }
    };
}

export function getLoginHeaders() {
    return {
        headers: {
            "X-Source": "monsieur"
        }
    };
}

export function getToken() {
    return localStorage.getItem(tokenKey);
}

export function getCurrentUser() {
    try {
        const token = getToken();
        return jwtDecode(token);
    } catch (e) {
        return null;
    }
}