import http from './httpService';
import {archivedBannersEndpoint, bannerEndpoint, bannersEndpoint} from "../constant/constants";
import {getAuthHeaders, getAuthHeadersWithBucketPath} from "./serviceHelper";

export async function getBanners() {
    return http.get(bannersEndpoint, getAuthHeaders())
}

export async function getArchivedBanners() {
    return http.get(archivedBannersEndpoint, getAuthHeaders())
}

export async function addBanner(body) {
    return http.post(bannerEndpoint, body, getAuthHeaders());
}

export async function deleteBanner(id, filename) {
    return http.delete(`${bannerEndpoint}/${id}/${filename}`, getAuthHeadersWithBucketPath("banners"))
}

export async function archiveBanner(id, body) {
    return http.patch(`${bannerEndpoint}/${id}`, body, getAuthHeaders());
}