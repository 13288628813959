import Auth from "./pages/auth";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Switch} from "react-router-dom";
import User from "./sections/user";
import ProtectedRoute from "./routes/protectedRoute";
import MainRoute from "./routes/mainRoute";
import Product from "./sections/product";
import DataProvider from "./components/DataContext";
import Banner from "./sections/banner";
import Order from "./sections/order";
import Blog from "./sections/blog";
import "./App.css";

function App() {
    return (
        <DataProvider>
            <Switch>
                <MainRoute path="/start" component={Auth}/>
                <ProtectedRoute path="/blogs" component={Blog}/>
                <ProtectedRoute path="/orders" component={Order}/>
                <ProtectedRoute path="/banners" component={Banner}/>
                <ProtectedRoute path="/products" component={Product}/>
                <ProtectedRoute exact path="/" component={User}/>
            </Switch>
            <ToastContainer/>
        </DataProvider>
    );
}

export default App;
