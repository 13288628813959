import React from 'react';
import {BsFillTrashFill} from "react-icons/bs";
import Table from "../../components/table";
import {toast} from "react-toastify";
import {deleteProductByID} from "../../services/productService";
import {ClickableSpan} from "../../components/table/tableBody/tableBodyElements";

const ProductTable = ({data, onSort, sortColumn, showModal, setCurrentProduct}) => {
    const columns = [
        {path: "image_url", label: "Image"},
        {path: "sku", label: "SKU"},
        {
            path: "name",
            label: "Name",
            content: (product) => (
                <ClickableSpan onClick={() => {
                    showModal();
                    setCurrentProduct(product);
                }}>
                    {product.name}
                </ClickableSpan>
            )
        },
        {path: "super_category", label: "Super Category"},
        {path: "category", label: "Category"},
        {path: "type", label: "Type"},
        {path: "price", label: "Price"},
        {path: "quantity", label: "Quantity"},
        {
            key: "delete",
            content: (products) => (
                <BsFillTrashFill
                    onClick={() => deleteProduct(products.id)}
                    style={{cursor: "pointer"}}
                />
            ),
        },
    ];

    const deleteProduct = async (id) => {
        try {
            const {status} = await deleteProductByID(id);
            if (status === 200) {
                toast.success("Product Successfully Deleted");
                window.location.reload();
            }
        } catch (e) {
            if (e.response && e.response.status === 400) {
                toast.error(e.response.data.error);
            }
        }

    }

    return (<Table
        columns={columns}
        data={data}
        onSort={onSort}
        sortColumn={sortColumn}
    />);
}

export default ProductTable;