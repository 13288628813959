import React from 'react';
import Table from "../../components/table";

const UserTable = ({data, onSort, sortColumn}) => {
    const columns = [
        {path: "full_name", label: "First Name"},
        {path: "referral_code", label: "Referral Code"},
        {path: "mobile_number", label: "Phone"},
        {path: "email", label: "E-mail"},
    ];

    return (
        <Table
            columns={columns}
            data={data}
            onSort={onSort}
            sortColumn={sortColumn}
        />
    );

}

export default UserTable;