import React from 'react';
import {FormLabel, InputWrapper} from "../formElements";
import {FormTextArea} from "./textAreaElements";

const TextArea = ({name, label, error, type, ...rest}) => {
    return (
        <InputWrapper>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <FormTextArea id={name} name={name} type={type} error={error} {...rest} />
        </InputWrapper>
    );
};

export default TextArea;
