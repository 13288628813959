import React, {useEffect, useState} from "react";
import {ContentState, convertFromHTML, EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styled from "styled-components";
import {draftToHTML} from "../../../utils/utils";
import {uploadImage} from "../../../services/productService";

export const RichTextWrapper = styled.div`
  margin: 0.25rem 0;
`;

const RichText = ({setContent, content = ""}) => {
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    useEffect(() => {
        const setupEditorState = () => {
            if (content !== "") {
                const blocksFromHTML = convertFromHTML(content);
                const {contentBlocks, entityMap} = blocksFromHTML;
                const state = EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        contentBlocks, entityMap
                    )
                );
                setEditorState(state);
            }
        }
        setupEditorState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML(state);
    };
    const convertContentToHTML = (editorState) => {
        let currentContentAsHTML = draftToHTML(editorState.getCurrentContent());
        setContent(currentContentAsHTML);
    };

    const uploadCallback = (file, callback) => {
        return new Promise(async (resolve, reject) => {
            const {status, data} = await uploadImage(file, "blogs");
            if (status === 200) {
                const imageURL = data["pathname"];
                resolve({data: {link: imageURL}});
            }
        });
    };

    const config = {
        options: [
            "inline",
            "blockType",
            "list",
            "textAlign",
            "history",
            "image",
            "emoji",
            "link",
        ],
        inline: {inDropdown: true},
        list: {inDropdown: true},
        textAlign: {inDropdown: true},
        link: {inDropdown: true},
        history: {inDropdown: true},
        image: {uploadCallback: uploadCallback},
    };

    return (
        <RichTextWrapper>
            <Editor
                toolbar={config}
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                editorClassName="draft-wrapper"
            />
        </RichTextWrapper>
    );
};

export default RichText;
