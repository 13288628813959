import React, {Component} from 'react';
import SearchBox from "../../../components/searchBox";
import {LoadingWrapper, SectionContentWrapper, SectionFlex} from "../../sectionElements";
import Lottie from "lottie-react";
import Loading from "../../../assets/lottie/loading.json";
import ProductTable from "../../../tables/productTable";
import Pagination from "../../../components/pagination";
import _ from "lodash";
import {paginate} from "../../../utils/utils";
import {getProducts} from "../../../services/productService";
import {toast} from "react-toastify";
import ModalForm from "../../../components/modalForm";
import ProductForm from "../productForm";

class ViewProduct extends Component {
    state = {
        pageSize: 10,
        currentPage: 1,
        products: [],
        sortColumn: {path: "name", order: "asc"},
        searchQuery: "",
        isLoading: false,
        showAddProductModal: false,
        currentProduct: {}
    };

    async componentDidMount() {
        try {
            this.setState({isLoading: true})
            const {status, data: products} = await getProducts();
            if (status === 200) {
                this.setState({products, isLoading: false});
            }
        } catch (e) {
            if (e.response && e.response.status === 400) {
                toast.error(e.response.data.error);
            }
        }
    }

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleSearch = (query) => {
        this.setState({searchQuery: query, currentPage: 1});
    };

    handleSort = (sortColumn) => {
        this.setState({sortColumn});
    };

    handleAddProductModal = () => {
        const showAddProductModal = !this.state.showAddProductModal;
        this.setState({showAddProductModal});
    }

    setCurrentProduct = (currentProduct) => {
        this.setState({currentProduct})
    }

    getSortedData = () => {
        const {
            pageSize,
            currentPage,
            products,
            sortColumn,
            searchQuery,
        } = this.state;

        let filtered = products;
        if (searchQuery)
            filtered = products.filter((product) =>
                product.name.toLowerCase().includes(searchQuery.toLowerCase())
            );

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
        const paginatedProducts = paginate(sorted, currentPage, pageSize);

        return {totalCount: sorted.length, sortedProducts: paginatedProducts};
    };


    render() {
        const {pageSize, showAddProductModal, sortColumn, searchQuery, isLoading, currentProduct} = this.state;

        const {totalCount, sortedProducts} = this.getSortedData();
        return (
            <SectionContentWrapper>
                <h1>Products</h1>
                <p>Showing {totalCount} products in the database</p>
                <SearchBox value={searchQuery} onChange={this.handleSearch}/>
                {isLoading ? <LoadingWrapper>
                    <Lottie animationData={Loading}/>
                </LoadingWrapper> : <SectionFlex flexBasis="700px">
                    <div><ProductTable
                        data={sortedProducts}
                        sortColumn={sortColumn}
                        onSort={this.handleSort}
                        showModal={this.handleAddProductModal}
                        setCurrentProduct={this.setCurrentProduct}
                    /></div>
                    <Pagination
                        itemsCount={totalCount}
                        pageSize={pageSize}
                        onPageChange={this.handlePageChange}
                    />
                </SectionFlex>}
                {showAddProductModal ? <ModalForm
                    show={showAddProductModal}
                    onHide={this.handleAddProductModal}
                    size="lg"
                    body={<ProductForm currentProduct={currentProduct}
                    />}
                /> : null}
            </SectionContentWrapper>
        );
    }
}

export default ViewProduct;