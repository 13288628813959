import React, {Component} from "react";
import _ from "lodash";
import {TableBodyImage} from "./tableBodyElements";

class TableBody extends Component {
    renderCell = (item, column) => {
        if (column.content) return column.content(item);

        if (item["created_at"]) {
            item["created_at"] = new Date(item["created_at"]).toLocaleDateString(
                "en-US"
            );
        }

        if (!item["target"]) {
            item["target"] = "all";
        }

        if (column['path'] === "image_url") {
            item["image_url"] = <TableBodyImage
                src={item["image_urls"][0]}
                alt=""
            />
        }
        //
        // if (item["image_urls"]) {
        //     item["image_urls"] = (
        //         <TableBodyImage
        //             src={item["image_urls"][0]}
        //             alt=""
        //         />
        //     );
        // }
        return _.get(item, column.path);
    };

    createKey = (item, column) => {
        return item.id + (column.path || column.key);
    };

    render() {
        const {data, columns} = this.props;

        return (
            <tbody>
            {data.map((item) => (
                <tr key={item.id}>
                    {columns.map((column) => (
                        <td key={this.createKey(item, column)}>
                            {this.renderCell(item, column)}
                        </td>
                    ))}
                </tr>
            ))}
            </tbody>
        );
    }
}

export default TableBody;