/*
|--------------------------------------------------------------------------
| HTTP AUTH SERVICE
|--------------------------------------------------------------------------
|
|
*/
export const authEndpoint = "/login";
export const tokenKey = "token";
export const logoutEndpoint = "/admin/logout";

/*
|--------------------------------------------------------------------------
| HTTP SECURED SERVICE
|--------------------------------------------------------------------------
|
|
*/
export const productsEndpoint = "/admin/products";
export const productEndpoint = "/admin/product"
export const usersEndpoint = "/admin/users";
export const bannersEndpoint = "/admin/banners";
export const archivedBannersEndpoint = "/admin/banners/archived";
export const bannerEndpoint = "/admin/banner";
export const ordersEndpoint = "/admin/orders";
export const orderEndpoint = "/admin/order";
export const uploadImageToGCloudEndpoint = "/admin/gcloud/upload";
export const deleteImageFromGCloudEndpoint = "/admin/gcloud/delete";
export const blogEndpoint = "/admin/blog";

/*
|--------------------------------------------------------------------------
| MENU THINGS
|--------------------------------------------------------------------------
|
|
*/
export const menuGet = "get"
export const menuGetArchived = "get_archived"
export const menuAdd = "add"