import React, {Component} from 'react';
import {SectionContainer} from "../sectionElements";
import MainWrapper from "../../components/mainWrapper";
import Navbar from "../../components/navbar";
import {DataContext} from "../../components/DataContext";
import {ProductMenu} from "../../data/navbarMenu";
import ViewProduct from "./viewProduct";
import {menuGet} from "../../constant/constants";
import AddProduct from "./addProduct";

class Product extends Component {
    static contextType = DataContext;

    render() {
        const {menu} = this.context;
        return (
            <MainWrapper>
                <Navbar menus={ProductMenu}/>
                <SectionContainer>
                    {
                        menu === menuGet ? (<ViewProduct/>) : (<AddProduct/>)
                    }
                </SectionContainer>
            </MainWrapper>
        );
    }
}

export default Product;