import styled from "styled-components";

export const NavbarWrapper = styled.nav`
  background-color: #eee;
  margin-left: 200px;
  padding: 1rem 2rem;
  position: fixed;
  width: calc(100% - 200px);
`;

export const NavbarFlex = styled.div`
  display: flex;
  justify-content: space-between;

  & > h1 {
    font-size: 14px;
    color: black;
    cursor: pointer;
  }
`;

export const NavbarMenuWrapper = styled.div`
  display: flex;

  & > h1 {
    font-size: 14px;
    margin-right: 2rem;
    cursor: pointer;
  }
`;