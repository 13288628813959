import React, {Fragment} from 'react';
import Form from "../../../components/form";
import {FormFlex, FormSectionWrapper, SectionContentWrapper} from "../../sectionElements";
import {
    accentsType,
    beddingAndBathCategories,
    carpetAndRugsCategories,
    collection,
    curtainsAndMaterialCategories,
    decorCategories,
    furnitureCategories,
    rooms,
    seatingTypes,
    storageDisplayTypes,
    superCategories,
    systemFurnitureCategories,
    tableTypes
} from "./addProductOptions";
import Joi from "joi-browser";
import {Images, ImageUpload} from "./addProductElements";
import {DeleteWrapper} from "../../../components/form/button/buttonElements";
import {addProduct, uploadImage} from "../../../services/productService";
import {toast} from "react-toastify";
import {Multiselect} from "multiselect-react-dropdown";
import {FormLabel} from "../../../components/form/formElements";

const Delete = require("../../../assets/icons/component/delete.png");

class AddProduct extends Form {
    state = {
        data: {
            sku: "",
            name: "",
            width: "",
            height: "",
            depth: "",
            detail_width: 0,
            detail_height: 0,
            detail_depth: 0,
            super_category: "",
            category: "",
            type: "",
            price: "",
            discount_price: "",
            weight: "",
            description: "",
            quantity: "",
            material: "",
            finishing: "",
            collection: "",
            colors: "",
        },
        errors: [],
        images: [],
        objectURLs: [],
        selectedRoom: [],
        rooms: []
    }

    schema = {
        sku: Joi.string().required().label("SKU"),
        name: Joi.string().required().label("Name"),
        description: Joi.string().required().label("Description"),
        quantity: Joi.number().min(0).label("Qty"),
        width: Joi.number().min(0).label("Product Width"),
        height: Joi.number().min(0).label("Product Height"),
        depth: Joi.number().min(0).label("Product Depth"),
        detail_width: Joi.number().min(0).label("Detail Width"),
        detail_height: Joi.number().min(0).label("Detail Height"),
        detail_depth: Joi.number().min(0).label("Detail Depth"),
        super_category: Joi.string().required().label("Super Category"),
        category: Joi.string().required().label("Category"),
        type: Joi.string().allow("").label("Type"),
        material: Joi.string().required().label("Material"),
        finishing: Joi.string().required().label("Finishing"),
        weight: Joi.number().min(0).label("Weight"),
        price: Joi.number().min(0).label("Price"),
        discount_price: Joi.number().min(0).label("Discount Price"),
        collection: Joi.string().required().label("Collection"),
        colors: Joi.string().required().label("Colors")
    }

    deleteImageLocally = (imageURL) => {
        const {objectURLs, images} = this.state;
        const objectURL = objectURLs.find((url) => url === imageURL);
        const filteredURLs = objectURLs.filter(function (url) {
            return url !== imageURL
        });

        const deletedURLIndex = objectURLs.indexOf(objectURL);
        const imageName = images[deletedURLIndex].name;
        const filteredImages = images.filter(function (image) {
            return image.name !== imageName
        });
        this.setState({objectURLs: filteredURLs, images: filteredImages});
    }

    doSubmit = async () => {
        const {images, data, rooms} = this.state;
        const productSizeObj = {
            width: data.width,
            height: data.height,
            depth: data.depth
        }

        const detailSizeObj = {
            width: data.detail_width,
            height: data.height,
            depth: data.depth
        }

        if (images.length === 0) {
            return;
        }


        toast.info("Adding Product ...");

        const urls = [];
        for (const image of images) {
            const {status, data} = await uploadImage(image, "products");
            if (status === 200) {
                const imageURL = data['pathname'];
                urls.push(imageURL)
            }
        }

        const body = {
            sku: data.sku,
            name: data.name,
            product_size: productSizeObj,
            detail_size: detailSizeObj,
            price: data.price,
            discount_price: data.discount_price,
            weight: data.weight,
            description: data.description,
            quantity: data.quantity,
            image_urls: urls,
            material: data.material,
            finishing: data.finishing,
            super_category: data.super_category,
            category: data.category,
            type: data.type,
            collection: data.collection,
            rooms: rooms,
            colors: data.colors
        };

        try {
            const {status} = await addProduct(body);
            toast.success("Product Added");
            if (status === 200) {
                setTimeout(function () {
                    window.location = "/products";
                }, 400);
            }
        } catch (e) {
            if (e.response && e.response.status === 400) {
                toast.error(e.response.data.error);
            }
        }
    }

    onSelect = (selectedList, selectedItem) => {
        const {rooms} = this.state;
        const addedRooms = [...rooms, selectedItem['id']];
        this.setState({selectedRoom: selectedList, rooms: addedRooms});
    }

    onRemove = (selectedList, removedItem) => {
        const {rooms} = this.state;
        const removedRooms = rooms.filter((room) => room !== removedItem.id);
        this.setState({selectedRoom: selectedList, rooms: removedRooms});
    }

    render() {
        const {objectURLs, selectedRoom} = this.state;
        const {super_category, category} = this.state.data;
        return (
            <SectionContentWrapper>
                <h1>Add a Product</h1>
                <form onSubmit={this.handleSubmit}>
                    <FormFlex>
                        <FormSectionWrapper>
                            <h1>Basic Detail of Product</h1>
                            <span>Please input some basic detail of the product</span>
                            {this.renderInput("sku", "SKU*")}
                            {this.renderInput("name", "Name*")}
                            {this.renderInput("quantity", "Quantity*", "", "number")}
                        </FormSectionWrapper>
                        <FormSectionWrapper>
                            <h1>Product Dimension</h1>
                            <span>Tell us the specific measurement of the product</span>
                            {this.renderInput("width", "Product Width*", "", "number")}
                            {this.renderInput("depth", "Product Depth*", "", "number")}
                            {this.renderInput("height", "Product Height*", "", "number")}
                        </FormSectionWrapper>
                    </FormFlex>
                    <FormFlex>
                        <FormSectionWrapper>
                            <h1>Product Category</h1>
                            <span>Tell us the specific category of the product</span>
                            {this.renderSelect(
                                "super_category",
                                superCategories,
                                "Super Category*"
                            )}
                            {this.renderSelect(
                                "category",
                                super_category === "Furniture"
                                    ? furnitureCategories
                                    : super_category === "System-Furniture"
                                        ? systemFurnitureCategories
                                        : super_category === "Artwork"
                                            ? decorCategories
                                            : super_category === "Curtains-Material"
                                                ? curtainsAndMaterialCategories
                                                : super_category === "Bedding-Bath"
                                                    ? beddingAndBathCategories
                                                    : super_category === "Carpet-Rugs"
                                                        ? carpetAndRugsCategories
                                                        : [],
                                "Category*"
                            )}
                            {this.renderSelect(
                                "type",
                                category === "Seating"
                                    ? seatingTypes
                                    : category === "Storage-Display"
                                        ? storageDisplayTypes
                                        : category === "Table"
                                            ? tableTypes
                                            : category === "Accents"
                                                ? accentsType
                                                : [],
                                "Type"
                            )}
                        </FormSectionWrapper>
                        <FormSectionWrapper>
                            <h1>Product Price $$</h1>
                            <span>Tell us the specific price of the product</span>
                            {this.renderInput("price", "Price*", "", "number")}
                            {this.renderInput("discount_price", "Discount Price", "", "number")}
                        </FormSectionWrapper>
                    </FormFlex>
                    <FormSectionWrapper>
                        <h1>Product Detail</h1>
                        <span>Tell us the specific detail of the product size</span>
                        {this.renderInput("material", "Material*")}
                        {this.renderInput("finishing", "Finishing*")}
                        {this.renderInput("weight", "Weight*", "", "number")}
                    </FormSectionWrapper>

                    <FormSectionWrapper>
                        <h1>Product Detail Size</h1>
                        <span>If there's any complimentary product on the main product, please specify the size too</span>
                        {this.renderInput("detail_width", "Detail Width", "", "number")}
                        {this.renderInput("detail_height", "Detail Height", "", "number")}
                        {this.renderInput("detail_depth", "Detail Depth", "", "number")}
                    </FormSectionWrapper>

                    <FormSectionWrapper>
                        <h1>Product Description</h1>
                        <span>Tell us the specific description of the product</span>
                        {this.renderTextArea("description", "Description*", "Beli kursi ini, dijamin punggung kamu akan lebih lurus dan sehat. Produktif deh ngerjain tugas.")}
                    </FormSectionWrapper>
                    <FormSectionWrapper>
                        <h1>Product Additional Details</h1>
                        <span>Tell us some additional detail of the product</span>
                        {this.renderSelect("collection", collection, "Collection*")}
                        {/*{this.renderSelect("room", room, "Room*")}*/}
                        <FormLabel style={{marginBottom: "0.5rem"}}>Room*</FormLabel>
                        {/*<FormSection>*/}
                        {/*    <FormSelect id="test" name="test" multiple>*/}
                        {/*        <option value=""/>*/}
                        {/*        {room.map((option) => (*/}
                        {/*            <option key={option.title || option} value={option.name || option}>*/}
                        {/*                {option.title || option}*/}
                        {/*            </option>*/}
                        {/*        ))}*/}
                        {/*    </FormSelect>*/}
                        {/*</FormSection>*/}
                        <Multiselect
                            options={rooms}
                            selectedValues={selectedRoom}
                            onSelect={this.onSelect}
                            onRemove={this.onRemove}
                            displayValue="name"
                            placeholder=""
                        />
                        {this.renderInput("colors", "Colors*")}
                    </FormSectionWrapper>

                    <FormSectionWrapper>
                        <h1>Product Images</h1>
                        <span>Upload some images for the product</span>
                        <Images>
                            {objectURLs &&
                                objectURLs.map((url) => (
                                    <Fragment key={url}>
                                        <img src={url} alt="" width={100}/>
                                        <DeleteWrapper onClick={() => this.deleteImageLocally(url)}>
                                            <img src={Delete} alt=""/>
                                        </DeleteWrapper>
                                    </Fragment>
                                ))
                            }
                            <ImageUpload
                                htmlFor="image_urls">
                                Upload
                            </ImageUpload>
                        </Images>
                        {this.renderInputFile("image_urls", "", "file", true)}
                    </FormSectionWrapper>
                    {this.renderButton("Submit")}
                </form>
            </SectionContentWrapper>
        );
    }
}

export default AddProduct;