import React, {Component} from 'react';
import {SectionContentWrapper} from "../../sectionElements";
import {getBlogs} from "../../../services/blogService";
import {BlogContent, BlogGrid, BlogStatus, BlogWrapper} from "./style";
import {timeSince} from "../../../utils/utils";
import ModalForm from "../../../components/modalForm";
import BlogDetail from "./blogDetail";
import EditBlog from "../edit";

class ViewBlogs extends Component {
    state = {
        data: [],
        blog: {},
        showModal: false,
        isEdit: false
    }

    async componentDidMount() {
        const {status, data} = await getBlogs({
            is_preview: true,
            limit: 10,
        })
        console.log(data);
        if (status === 200) {
            this.setState({data})
        }
    }

    setShowModal = () => {
        const {showModal} = this.state;
        this.setState({showModal: !showModal, isEdit: false});
    }

    setIsEdit = () => {
        const {isEdit} = this.state;
        this.setState({isEdit: !isEdit})
    }

    render() {
        const {data, showModal, blog, isEdit} = this.state;
        return (
            <SectionContentWrapper>
                {data.length > 0 ? (<BlogGrid>
                    {data.map((blog, index) => <BlogWrapper key={index} onClick={() => {
                        this.setState({blog});
                        this.setShowModal();
                    }}>
                        <BlogStatus background={blog['is_active'] === 0 ? "rgb(245,147,27)" : "rgb(0,171,107)"}>
                            <h3>{blog['is_active'] === 0 ? "Draft" : "Published"}</h3>
                        </BlogStatus>
                        <img src={blog['image_url']} alt=""/>
                        <BlogContent>
                            <h2>{blog['category']}</h2>
                            <h1>{blog['title']}</h1>
                            <span>created {timeSince(new Date(blog['created_at']))}</span>
                        </BlogContent>
                    </BlogWrapper>)}
                </BlogGrid>) : <h1>No Data</h1>}
                <ModalForm
                    onHide={this.setShowModal}
                    show={showModal}
                    size={isEdit ? "lg" : "m"}
                    body={isEdit ? (<EditBlog id={blog['id']}/>) : <BlogDetail blog={blog} setIsEdit={this.setIsEdit}/>}
                />
            </SectionContentWrapper>
        );
    }
}

export default ViewBlogs;