import React from 'react';
import {AuthBody, AuthHeader, AuthSectionWrapper, AuthWrapper} from "./authElements";
import AuthForm from "./authForm";

const Auth = (props) => {
    return <AuthSectionWrapper>
        <AuthWrapper>
            <AuthHeader>
                <h1>Cataluna</h1>
                <span>Abbasource Core Monitoring Site</span>
            </AuthHeader>
            <AuthBody>
                <AuthForm/>
            </AuthBody>
        </AuthWrapper>
    </AuthSectionWrapper>
}

export default Auth;