import React, {Fragment} from 'react';
import Form from "../../../components/form";
import {FormSectionWrapper, SectionContentWrapper} from "../../sectionElements";
import {Images, ImageUpload} from "../../product/addProduct/addProductElements";
import {DeleteWrapper} from "../../../components/form/button/buttonElements";
import Delete from "../../../assets/icons/component/delete.png";
import Joi from "joi-browser";
import {uploadImage} from "../../../services/productService";
import {toast} from "react-toastify";
import {addBanner} from "../../../services/bannerService";

class AddBanner extends Form {
    state = {
        data: {
            name: ""
        },
        errors: [],
        objectURLs: [],
        images: [],
        isSubmit: false
    }

    schema = {
        name: Joi.string().required().label("Name"),
    }

    deleteImageLocally = (imageURL) => {
        const {objectURLs, images} = this.state;
        const objectURL = objectURLs.find((url) => url === imageURL);
        const filteredURLs = objectURLs.filter(function (url) {
            return url !== imageURL
        });

        const deletedURLIndex = objectURLs.indexOf(objectURL);
        const imageName = images[deletedURLIndex].name;
        const filteredImages = images.filter(function (image) {
            return image.name !== imageName
        });
        this.setState({objectURLs: filteredURLs, images: filteredImages});
    }

    doSubmit = async () => {
        const {images, data, isSubmit} = this.state;
        this.setState({isSubmit: true});
        if (!isSubmit) {
            const urls = [];
            toast.info("Uploading banner ...")
            for (const image of images) {
                const {status, data} = await uploadImage(image, "banners");
                if (status === 200) {
                    const imageURL = data['pathname'];
                    urls.push(imageURL)
                }
            }


            try {
                const body = {
                    name: data.name,
                    image_url: urls[0],
                    is_active: true
                }
                const {status} = await addBanner(body);
                if (status === 200) {
                    toast.success("Banner Added");
                    this.setState({isSubmit: false})
                    setTimeout(function () {
                        window.location = "/banners";
                    }, 400)
                }
            } catch (e) {
                if (e.response && e.response.status === 400) {
                    toast.error(e.response.data.error);
                }
            }
        } else {
            toast.warning("Mohon bersabar, sedang upload. Cek internet kamu ya")
        }
    }

    render() {
        const {objectURLs} = this.state;
        return (
            <SectionContentWrapper>
                <form onSubmit={this.handleSubmit}>
                    <FormSectionWrapper>
                        <h1>Details</h1>
                        <span>Tell us what banner is this</span>
                        {this.renderInput("name", "Name", "Banner Promo")}
                    </FormSectionWrapper>
                    <FormSectionWrapper>
                        <h1>Banner</h1>
                        <span>Upload an image of active banner</span>
                        <Images>
                            {objectURLs &&
                                objectURLs.map((url) => (
                                    <Fragment key={url}>
                                        <img src={url} alt="" width={100}/>
                                        <DeleteWrapper onClick={() => this.deleteImageLocally(url)}>
                                            <img src={Delete} alt=""/>
                                        </DeleteWrapper>
                                    </Fragment>
                                ))
                            }
                            <ImageUpload
                                htmlFor="image">
                                Upload
                            </ImageUpload>
                        </Images>
                        {this.renderInputFile("image", "", "file", false)}
                    </FormSectionWrapper>
                    {this.renderButton("Submit")}
                </form>
            </SectionContentWrapper>
        );
    }
}

export default AddBanner;