import React, {Component} from 'react';

export const DataContext = React.createContext();

class DataProvider extends Component {
    state = {
        menu: "get"
    }

    setMenu = (menu) => {
        this.setState({menu})
    }

    render() {
        const {menu} = this.state;
        const {setMenu} = this;
        return (
            <DataContext.Provider
                value={{
                    menu,
                    setMenu
                }}>
                {this.props.children}
            </DataContext.Provider>
        );
    }
}

export default DataProvider;