import styled from "styled-components";

export const FormLabel = styled.label`
  font-size: 14px;
  font-weight: bold;
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin-top: 0.5rem;
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.5rem;
`;