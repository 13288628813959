import http from './httpService';
import {blogEndpoint} from "../constant/constants";
import {getAuthHeaders} from "./serviceHelper";

export async function createBlog(body) {
    return http.post(blogEndpoint, body, getAuthHeaders());
}

export async function getBlogs(filter) {
    return http.post("/admin/blogs", filter, getAuthHeaders());
}

export async function getBlogByID(id) {
    return http.get(`/admin/blog/${id}`, getAuthHeaders())
}

export async function updateBlog(body) {
    return http.put("/admin/blog", body, getAuthHeaders())
}