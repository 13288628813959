import styled from "styled-components";

export const Images = styled.div`
  margin: 1rem 0;
  display: flex;

  & > img {
    border-radius: 10px;
    border: 1px solid #dfe3e9;
    height: 125px;
  }
`;

export const ImageUpload = styled.label`
  border-radius: 20px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: 2px dashed #dfe3e9;
  width: 125px;
  height: 125px;
  font-size: 14px;
  margin: 0;
`;