import styled from "styled-components";

export const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2rem;
`;

export const BlogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: white;
  position: relative;

  & > img {
    border-radius: 8px;
  }

`;

export const BlogContent = styled.div`
  padding: 1rem;

  & > h1 {
    font-size: 16px;
    margin: 8px 0;
  }

  & > p {
    font-size: 14px;
    margin-bottom: 1rem;
  }

  & > span {
    font-size: 12px;
  }

  & > h2 {
    color: rgb(181, 181, 181);
    font-size: 13px;
    line-height: 135%;
  }
`;

export const BlogHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  & > h2 {
    font-size: 14px;
    color: #fff;
    background: #333;
    border-radius: 8px;
    padding: 4px 8px;
    align-self: flex-start;
  }

  & > h3 {
    font-size: 14px;
  }
`;

export const BlogStatus = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: ${({background}) => background};
  padding: 4px 8px;
  border-radius: 4px;

  & > h3 {
    font-size: 14px;
    color: #fff;
  }
`;