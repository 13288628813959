import React, {Component} from 'react';
import MainWrapper from "../../components/mainWrapper";
import {DataContext} from "../../components/DataContext";
import Navbar from "../../components/navbar";
import {BlogsMenu} from "../../data/navbarMenu";
import {menuGet} from "../../constant/constants";
import AddBlog from "./addBlog";
import {SectionContainer} from "../sectionElements";
import ViewBlogs from "./viewBlogs";

class Blog extends Component {
    static contextType = DataContext;

    render() {
        const {menu} = this.context;
        return (
            <MainWrapper>
                <Navbar menus={BlogsMenu}/>
                <SectionContainer style={{
                    background: "rgb(249, 249, 249)"
                }}>
                    {
                        menu === menuGet ? (<ViewBlogs/>) : <AddBlog/>
                    }
                </SectionContainer>
            </MainWrapper>
        );
    }
}

export default Blog;