import React from 'react';
import Form from "../../../components/form";
import Joi from "joi-browser";
import {toast} from "react-toastify";
import {login} from "../../../services/authService";

class AuthForm extends Form {
    state = {
        data: {
            email: "",
            password: ""
        },
        errors: []
    }

    schema = {
        email: Joi.string().required().label("Email"),
        password: Joi.string().required().label("Password"),
    };

    doSubmit = async () => {
        try {
            const {data} = this.state;
            const body = {
                email: data.email,
                password: data.password
            };
            await login(body);
            setTimeout(function () {
                window.location = "/";
            }, 200);
        } catch (e) {
            if (e.response && e.response.status === 400) {
                toast.error(e.response.data.error);
            }
        }
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                {this.renderInput("email", "", "Email")}
                {this.renderInput("password", "", "Password", "password")}
                {this.renderButton("Sign In")}
            </form>
        );
    }
}

export default AuthForm;