import React, {Fragment} from 'react';
import Form from "../../../components/form";
import {ModalFormWrapper} from "../../../components/modalForm/globalModalElements";
import {getBlogByID, updateBlog} from "../../../services/blogService";
import Lottie from "lottie-react";
import Loading from "../../../assets/lottie/loading.json";
import {FormSectionWrapper, LoadingWrapper} from "../../sectionElements";
import Joi from "joi-browser";
import {BlogCategory} from "../../../data/menu";
import {FormLabel} from "../../../components/form/formElements";
import RichText from "../../../components/form/richText";
import {Images, ImageUpload} from "../../product/addProduct/addProductElements";
import {DeleteWrapper} from "../../../components/form/button/buttonElements";
import Delete from "../../../assets/icons/component/delete.png";
import {toast} from "react-toastify";
import {deleteImage, uploadImage} from "../../../services/productService";

class EditBlog extends Form {
    state = {
        data: {
            title: "",
            content: "",
            preview_text: "",
            category: "",
            image_url: "",
            tags: "",
        },
        errors: {},
        isLoading: true,
        objectURLs: [],
        images: [],
        deletedImage: "",
        isUpdating: false,
        blog_id: 0,
        is_active: 0
    };

    schema = {
        title: Joi.string().required(),
        preview_text: Joi.string().required(),
        category: Joi.string().required(),
        content: Joi.string().required(),
        tags: Joi.string().allow(""),
        image_url: Joi.string().allow("")
    };

    async componentDidMount() {
        const {id} = this.props;
        const {status, data} = await getBlogByID(id)
        if (status === 200) {
            const id = data['id'];
            const is_active = data['is_active'];
            delete data['id'];
            delete data['created_at'];
            delete data['updated_at'];
            delete data['is_active'];
            this.setState({data, isLoading: false, blog_id: id, is_active})
        }
    }

    setContent = (content) => {
        const {data} = this.state;
        data["content"] = content;
        this.setState({data});
    };

    deleteImage = async (image) => {
        const {data} = this.state;
        data.image_url = "";
        this.setState({deletedImage: image, data});
    }

    deleteImageLocally = (imageURL) => {
        const {objectURLs, images} = this.state;
        const objectURL = objectURLs.find((url) => url === imageURL);
        const filteredURLs = objectURLs.filter(function (url) {
            return url !== imageURL
        });

        const deletedURLIndex = objectURLs.indexOf(objectURL);
        const imageName = images[deletedURLIndex].name;
        const filteredImages = images.filter(function (image) {
            return image.name !== imageName
        });
        this.setState({objectURLs: filteredURLs, images: filteredImages});
    }

    doSubmit = async () => {
        const {data, deletedImage, images, isUpdating, is_active, blog_id} = this.state;

        if (!isUpdating) {
            if (deletedImage !== "") {
                try {
                    const {status} = await deleteImage(deletedImage, "blogs");
                    if (status !== 200) {
                        return
                    }
                } catch (e) {
                    if (e.response && e.response.status === 400) {
                        toast.error(e.response.data.error)
                    }
                }
            }

            let imageURL = "";
            if (images.length !== 0) {
                for (const image of images) {
                    try {
                        const {status, data} = await uploadImage(image, "blogs");
                        if (status === 200) {
                            imageURL = data['pathname'];
                        }
                    } catch (e) {
                        if (e.response && e.response.status === 400) {
                            toast.error(e.response.data.error)
                        }
                    }
                }
                data.image_url = imageURL
            }

            const body = {
                id: blog_id,
                title: data.title,
                category: data.category,
                image_url: data.image_url,
                content: data.content,
                preview_text: data.preview_text,
                tags: data.tags,
                is_active: is_active,
            }
            try {
                const {status} = await updateBlog(body);
                if (status === 200) {
                    toast.success("Blog Updated");
                    setTimeout(function () {
                        window.location = "/blogs";
                    }, 500);
                } else {
                    toast.warning(status);
                }
            } catch (e) {
                if (e.response && e.response.status === 400) {
                    toast.error(e.response.data.error);
                }
            }
        } else {
            toast.warning("Updating Blog ...");
        }
    }

    setStatus = () => {
        const {is_active} = this.state;
        this.setState({is_active: is_active === 0 ? 1 : 0})
    }

    render() {
        const {isLoading, data, objectURLs, is_active} = this.state;
        return (
            <ModalFormWrapper>
                {isLoading ? <LoadingWrapper>
                    <Lottie animationData={Loading}/>
                </LoadingWrapper> : <form onSubmit={this.handleSubmit}>
                    {this.renderInput("title", "Title")}
                    <FormLabel>Status</FormLabel>
                    <p style={{fontSize: "13px", marginTop: "4px"}}>{is_active === 1 ? "Published" : "Draft"}</p>
                    <span style={{
                        display: "inline-block",
                        marginBottom: "1rem",
                        fontSize: "13px",
                        textDecoration: "underline",
                        cursor: "pointer"
                    }} onClick={this.setStatus}>Switch to {is_active === 1 ? "Draft" : "Publish"}</span>
                    {this.renderSelect("category", BlogCategory, "Category")}
                    {this.renderInput("tags", "Tags (Comma Separated)")}
                    {this.renderTextArea("preview_text", "Preview Text")}
                    <FormLabel>
                        Content
                    </FormLabel>
                    <RichText setContent={this.setContent} content={data.content}/>
                    <FormSectionWrapper>
                        <Images>
                            {objectURLs &&
                                objectURLs.map((url) => (
                                    <Fragment key={url}>
                                        <img src={url} alt="" width={100}/>
                                        <DeleteWrapper onClick={() => this.deleteImageLocally(url)}>
                                            <img src={Delete} alt=""/>
                                        </DeleteWrapper>
                                    </Fragment>
                                ))
                            }
                            {data.image_url !== "" && <Fragment key={data.image_url}>
                                <img src={data.image_url} alt="" width={125}/>
                                <DeleteWrapper onClick={() => this.deleteImage(data.image_url)}>
                                    <img src={Delete} alt=""/>
                                </DeleteWrapper>
                            </Fragment>}
                            <ImageUpload
                                htmlFor="image_url">
                                Upload
                            </ImageUpload>
                        </Images>
                        {this.renderInputFile("image_url", "", "file", true)}
                    </FormSectionWrapper>
                    {this.renderButton('Update')}
                </form>}
            </ModalFormWrapper>
        );
    }
}

export default EditBlog;