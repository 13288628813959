import http from './httpService';
import {orderEndpoint, ordersEndpoint} from "../constant/constants";
import {getAuthHeaders} from "./serviceHelper";

export async function getOrders() {
    return http.get(ordersEndpoint, getAuthHeaders());
}

export async function getOrderByID(id) {
    return await http.get(`${orderEndpoint}/${id}`, getAuthHeaders());
}