import React, {Component} from 'react';
import {getUsers} from "../../services/userService";
import {toast} from "react-toastify";
import Lottie from 'lottie-react';
import {LoadingWrapper, SectionContainer, SectionContentWrapper, SectionFlex} from "../sectionElements";
import MainWrapper from "../../components/mainWrapper";
import UserTable from "../../tables/userTable";
import {paginate} from "../../utils/utils";
import _ from "lodash";
import SearchBox from "../../components/searchBox";
import Pagination from "../../components/pagination";
import Loading from "../../assets/lottie/loading.json"
import Navbar from "../../components/navbar";

class User extends Component {
    state = {
        pageSize: 10,
        currentPage: 1,
        users: [],
        sortColumn: {path: "full_name", order: "asc"},
        searchQuery: "",
        isLoading: false,
    };


    async componentDidMount() {
        try {
            this.setState({isLoading: true})
            const {status, data: users} = await getUsers();
            if (status === 200) {
                this.setState({users, isLoading: false});
            }
        } catch (e) {
            if (e.response && e.response.status === 400) {
                toast.error(e.response.data.error);
            }
        }
    }

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleSearch = (query) => {
        this.setState({searchQuery: query, currentPage: 1});
    };

    handleSort = (sortColumn) => {
        this.setState({sortColumn});
    };

    getSortedData = () => {
        const {
            pageSize,
            currentPage,
            users,
            sortColumn,
            searchQuery,
        } = this.state;

        let filtered = users;
        if (searchQuery)
            filtered = users.filter((user) =>
                user.full_name.toLowerCase().includes(searchQuery.toLowerCase())
            );

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
        const paginatedUsers = paginate(sorted, currentPage, pageSize);

        return {totalCount: sorted.length, sortedUsers: paginatedUsers};
    };

    render() {
        const {totalCount, sortedUsers} = this.getSortedData();
        const {pageSize, sortColumn, searchQuery, isLoading} = this.state;
        return (
            <MainWrapper>
                <Navbar/>
                <SectionContainer>
                    <SectionContentWrapper>
                        <h1>User Data</h1>
                        <p>Showing {totalCount} users in the database</p>
                        <SearchBox value={searchQuery} onChange={this.handleSearch}/>
                        {isLoading ? <LoadingWrapper>
                            <Lottie animationData={Loading}/>
                        </LoadingWrapper> : <SectionFlex flexBasis="500xp">
                            <div><UserTable
                                data={sortedUsers}
                                sortColumn={sortColumn}
                                onSort={this.handleSort}
                            /></div>
                            <Pagination
                                itemsCount={totalCount}
                                pageSize={pageSize}
                                onPageChange={this.handlePageChange}
                            />
                        </SectionFlex>}
                    </SectionContentWrapper>
                </SectionContainer>
            </MainWrapper>
        );
    }
}

export default User;