import React, {Component} from 'react';
import {LoadingWrapper, SectionContentWrapper} from "../../sectionElements";
import {toast} from "react-toastify";
import {archiveBanner, deleteBanner, getBanners} from "../../../services/bannerService";
import Lottie from "lottie-react";
import Loading from "../../../assets/lottie/loading.json";
import {BannerFlexOption, BannerGridWrapper, BannerWrapper} from "./viewBannerElements";

class ViewBanners extends Component {
    state = {
        pageSize: 10,
        currentPage: 1,
        banners: [],
        sortColumn: {path: "name", order: "asc"},
        searchQuery: "",
        isLoading: false,
    };


    async componentDidMount() {
        try {
            this.setState({isLoading: true})
            const {status, data: banners} = await getBanners();
            if (status === 200) {
                this.setState({banners, isLoading: false});
            }
        } catch (e) {
            if (e.response && e.response.status === 400) {
                toast.error(e.response.data.error);
            }
        }
    }

    deleteBanner = async (id, imageURL) => {
        try {
            const split = imageURL.split('/');
            const {status} = await deleteBanner(id, decodeURIComponent(split[6]));
            if (status === 200) {
                toast.success("Success delete banner");
                setTimeout(function () {
                    window.location = "/banners";
                }, 400);
            }
        } catch (e) {
            if (e.response && e.response.status === 400) {
                toast.error(e.response.data.error);
            }
        }
    }

    archiveBanner = async (id) => {
        try {
            const body = {
                is_active: false
            }
            const {status} = await archiveBanner(id, body);
            if (status === 200) {
                toast.success("Success archiving banner");
                setTimeout(function () {
                    window.location = "/banners";
                }, 400);
            }
        } catch (e) {
            if (e.response && e.response.status === 400) {
                toast.error(e.response.data.error);
            }
        }
    }


    render() {
        const {banners, isLoading} = this.state;
        return (
            <SectionContentWrapper>
                <h1>Banners</h1>
                {isLoading ? <LoadingWrapper>
                    <Lottie animationData={Loading}/>
                </LoadingWrapper> : <BannerGridWrapper>
                    {banners.map((banner, index) => <BannerWrapper key={index}>
                        <img src={banner['image_url']} alt={index}/>
                        <BannerFlexOption>
                            <h1 onClick={() => this.deleteBanner(banner['id'], banner['image_url'])}>Delete</h1>
                            <h1 onClick={() => this.archiveBanner(banner['id'])}>Archive</h1>
                        </BannerFlexOption>
                    </BannerWrapper>)}
                </BannerGridWrapper>}
            </SectionContentWrapper>
        );
    }
}

export default ViewBanners;