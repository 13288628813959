import TimeAgo from "javascript-time-ago";
import _ from "lodash";
import {convertFromHTML, convertToHTML} from "draft-convert";

// English.
import en from "javascript-time-ago/locale/en.json";

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo("en-US");

export function timeSince(date) {
    return timeAgo.format(date);
}

export function paginate(items, pageNumber, pageSize) {
    const startIndex = (pageNumber - 1) * pageSize;
    //_.slice(items, startIndex);
    return _(items).slice(startIndex).take(pageSize).value();
}


export const draftToHTML = convertToHTML({
    //eslint-disable-next-line react/display-name
    styleToHTML: (style) => {
        if (style === "STRIKETHROUGH") {
            return <span style={{textDecoration: "line-through"}}/>;
        }
    },
    entityToHTML: (entity, originalText) => {
        if (entity.type === "image" || entity.type === "IMAGE") {
            let classNames = "draft-image ";
            if (entity.data.alignment) {
                classNames = classNames + entity.data.alignment;
            }
            let style = "width:" + (entity.data.width || 40) + "%";
            return `<figure><img src="${entity.data.src}" class="${classNames}" style="${style}" width="100%"/></figure>`;
        }
        if (entity.type === "LINK") {
            return {
                start: `<a href="${entity.data.url || entity.data.href}">`,
                end: "</a>",
            };
        }
        if (entity.type === "IMG") {
            const className = "draft-inline-image";
            return `<img src="${entity.data.src}" class="${className}" alt="${entity.data.alt}" width="100%"/>`;
        }
        if (entity.type === "INLINETEX") {
            if (entity.data.html) {
                return `<span>${
                    entity.data.css ? `<style>${entity.data.css}</style>` : ""
                }${entity.data.html}</span>`;
            } else {
                return `<span class="draft-latex-placeholder"> &lt; refresh to render LaTeX &gt; </span>`;
            }
        }
        return originalText;
    },
    //eslint-disable-next-line react/display-name
    blockToHTML: (block) => {
        const type = block.type;
        if (type === "atomic") {
            if (block.data && block.data.mathjax && block.data.html) {
                return `<div>${
                    block.data.css ? `<style>${block.data.css}</style>` : ""
                }${block.data.html}</div>`;
            } else if (block.data && block.data.mathjax) {
                return `<div class="draft-latex-placeholder-block"> &lt;refresh to render LaTeX&gt; </div>`;
            } else {
                return {start: "<span>", end: "</span>"};
            }
        }
        if (type === "blockquote") {
            return <blockquote/>;
        }
        if (type === "code") {
            return {
                start: '<pre style="background-color: #eee; padding: 1rem"><code>',
                end: "</code></pre>",
            };
        }
        if (type === "divider") {
            return <hr className="dividerBlock"/>;
        }
        if (type === "spoiler") {
            return <p className="spoiler"/>;
        }
        if (type === "unstyled") {
            if (block.text === " " || block.text === "") return <br/>;
            return <p/>;
        }
        //  return <span/>;
    },
});

export const htmlToDraft = convertFromHTML({
    htmlToEntity: (nodeName, node, createEntity) => {
        if (nodeName === "img") {
            return createEntity("IMAGE", "IMMUTABLE", {src: node.src});
        }
        if (nodeName === "a") {
            return createEntity("LINK", "MUTABLE", {url: node.href});
        }
        // if (nodeName === 'img') {
        //   return createEntity(
        //     'IMAGE',
        //     'IMMUTABLE',
        //     {src: node.src}
        //   )
        // }
    },
    htmlToBlock: (nodeName, node, lastList, inBlock) => {
        const isAtomicBlock =
            nodeName === "div" && node.dataset.blocktype === "atomic";
        if (isAtomicBlock || (nodeName === "img" && inBlock !== "atomic")) {
            return "atomic";
        }

        return undefined;
    },
});
