import styled from "styled-components";

export const TableBodyImage = styled.img`
  border-radius: 10px;
  width: 85px;
`;

export const ClickableSpan = styled.span`
  cursor: pointer;
  color: darkblue;
  padding-bottom: 1px;
  border-bottom: 1px solid #333;
`;